import { Container, Row, Col } from "react-bootstrap"
import 'animate.css'
import img1 from '../assets/img/3a547136-f659-45c6-859a-6c28c2fa8368.jpeg'
import img2 from '../assets/img/vaiquecola.jpeg'
import { useState } from "react"
import { TestCard } from "./TestCard"

export const Test = () => {
  const [changeClass, setChangeClass] = useState(true)

  return (
    <section className="contact" id="sobre">
      <Container>
        <div className="body">
          <Row className="align-items-center ">
            <Col size={12} md={6} className="sobre-col">
              <h2 className="sobre-h2">Sobre nós:</h2>
              <p className="sobre-p">A HB Garden é uma empresa consolidada há mais de duas décadas no mercado, sendo referência em aluguel e venda de plantas, paisagismo e decoração de eventos. Ao longo dos anos, a empresa se destacou por oferecer serviços que aliam sofisticação, sustentabilidade e criatividade, criando soluções personalizadas para clientes que buscam transformar seus ambientes em espaços de beleza natural e bem-estar.

                Especializada na criação e manutenção de jardins, a HB Garden atende tanto residências quanto empresas, proporcionando projetos de paisagismo que combinam funcionalidade e estética. A empresa oferece uma vasta gama de plantas, desde as mais exóticas até as mais comuns, garantindo que cada jardim, varanda ou ambiente interno seja adaptado ao gosto e às necessidades de seus clientes. Além disso, a HB Garden cuida de todo o processo de implantação e manutenção, assegurando que os espaços verdes permaneçam sempre saudáveis e vibrantes.
                <p className="ident"></p>
                Outro grande diferencial da HB Garden é seu trabalho na decoração de eventos. Combinando a beleza das plantas e a criatividade do paisagismo, a empresa transforma casamentos, festas e celebrações corporativas em verdadeiros espetáculos visuais. O toque verde e elegante das suas decorações agrega um charme especial, tornando cada evento único e inesquecível.
                <p className="ident"></p>
                A HB Garden também expandiu sua atuação para o universo do entretenimento, participando de produções de grande sucesso como "Minha Mãe é uma Peça" e "Vai que Cola". A empresa é responsável pela criação e manutenção dos cenários paisagísticos de filmes, séries e programas de TV, trazendo um toque de natureza que enriquece o visual das produções. Seu trabalho minucioso e criativo contribui para que cada cenário se destaque, adicionando profundidade e autenticidade às histórias contadas na tela.
                <p className="ident"></p>
                Com compromisso com a excelência, a HB Garden é sinônimo de qualidade e inovação no setor de paisagismo, sempre buscando novas formas de integrar a natureza aos espaços urbanos e cenográficos, enriquecendo a experiência das pessoas em todos os lugares onde suas plantas e designs se fazem presentes.</p>
              {/* TELA PEQUENA */}
              {window.innerWidth < 975 && <div className="container-sobre-image-S">
                <img src={img1} className="sobre-image-S" alt='' />
                <img src={img2} className="sobre-image-S" alt='' />
              </div>}
            </Col>
            <Col>
              {window.innerWidth > 975 && <div className="container-sobre-image-G">
                <div className="proj-imgbx2">
                  <img src={img1} className="sobre-image-G" alt='' />
                  <div className="proj-txtx2">
                    <h4>Minha Mãe é Uma Peça 3</h4>
                  </div>
                </div>


              </div>}
              {window.innerWidth > 975 && <div className="container-sobre-image-G">
                <div className="proj-imgbx2">
                  <img src={img2} className="sobre-image-G" alt='' />
                  <div className="proj-txtx2">
                    <h4>Vai que cola</h4>
                  </div>
                </div>


              </div>}
            </Col>
          </Row>

          {/* <Row>
            <Col size={12} sm={6} className="px-1 sasasa">
              {window.innerWidth > 975 &&
                <>
                  <img src={img2} className="sobre-image" />
                  <img src={img2} className="sobre-image" />
                </>
              }
            </Col>
          </Row> */}
        </div>
      </Container>

    </section >

  )
}
