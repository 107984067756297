import { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../assets/img/logo3.png'
import nav1 from '../assets/img/nav-icon1.svg'
import nav2 from '../assets/img/01-27-7.svg'
import nav3 from '../assets/img/nav-icon3.svg'

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('')
  const [scrolled, setScrolled] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const updateActiveLink = (value) => {
    setActiveLink(value)
  }

  const handleToggle = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    if (!menuOpen) {
      setScrolled(false)
    }
  }, [menuOpen])

  return (
    <Navbar expand="lg" className={(menuOpen || (scrolled && window.innerWidth > 975)) ? 'scrolled' : ''}>
      <Container>
        <Navbar.Brand href="#inicio">
          {window.innerWidth > 975 && <img src={logo} alt='logo' className='logohidden' />}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          <span className='navbar-toggler-icon'></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="#inicio"
              className={activeLink === 'inicio' ? 'inicio active navbar-link' : 'inicio navbar-link'}
              onClick={() => updateActiveLink('inicio')}
            >
              Início
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className={activeLink === 'projects' ? 'projects active navbar-link' : 'projects navbar-link'}
              onClick={() => updateActiveLink('projects')}
            >
              Produtos
            </Nav.Link>
            <Nav.Link
              href="#sobre"
              className={activeLink === 'sobre' ? 'sobre active navbar-link' : 'sobre navbar-link'}
              onClick={() => updateActiveLink('sobre')}
            >
              Sobre
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            {window.innerWidth > 975 && <> <span className='vvd'>Faça um orçamento conosco!</span><span>          </span></>}
            <div className='social-icon'>
              {/* <a href=''><img src={nav1} alt='' /></a> */}
              <a href='https://w.app/hbgarden' target='_blank' rel='noonpener noreferrer'><img className='whatsapp' src={nav2} alt='' /></a>
              <a href='https://www.instagram.com/hbgardenplantas/' target="_blank" rel="noopener noreferrer"><img src={nav3} alt='' /></a>
            </div>

          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
