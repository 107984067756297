import { Container, Row, Col, Tab, Nav } from "react-bootstrap"
import * as React from 'react'
import colorSharp2 from "../assets/img/color-sharp2.png"
import 'animate.css'
import TrackVisibility from 'react-on-screen'
import { ProjectCard, ProjectCard2, ProjectCard3, ProjectCard4 } from "./ProjectCard.js"



export const Projects = () => {


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2 className="projetinhos">Produtos</h2>
                  <Tab.Container id="projects-tabs" className="teste1" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-3 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Plantas</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Cenários</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="third">Vasos</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Decorações</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifith">Jardins</Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <p>Atuamos pela cidade do Rio de Janeiro e pela Região dos Lagos!

                          <div>  Todos os vasos mostrados são de propriedade HB Garden e podem ser alugados ou comprados.</div>
                        </p>


                        <Row>

                          <ProjectCard />

                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <p>Atuamos pela cidade do Rio de Janeiro e pela Região dos Lagos!

                          <div>  Todos os vasos mostrados são de propriedade HB Garden e podem ser alugados ou comprados.</div>
                        </p>                        <Row>
                          <ProjectCard2 />
                        </Row>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="third">
                        <p>Todos os Vasos mostrados são de propriedade HB Garden e podem ser alugados ou comprados.</p>
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="fourth">
                        <p>Atuamos pela cidade do Rio de Janeiro e pela Região dos Lagos!

                          <div>  Todos os vasos mostrados são de propriedade HB Garden e podem ser alugados ou comprados.</div>
                        </p>                        <Row>
                          <ProjectCard3 />
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifith">
                        <p>Atuamos pela cidade do Rio de Janeiro e pela Região dos Lagos!

                          <div>  Todos os vasos mostrados são de propriedade HB Garden e podem ser alugados ou comprados.</div>
                        </p>                        <Row>
                          <ProjectCard4 />
                        </Row>
                      </Tab.Pane>

                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
