import { Col } from "react-bootstrap"
import Viewer from 'react-viewer'
import * as React from 'react'
import { useWindowSize } from "./useWindowSize"

import P1 from "../assets/img/PLANTAS0.jpeg"
import P2 from "../assets/img/PLANTAS1.jpeg"
import P3 from "../assets/img/PLANTAS2.jpeg"
import P4 from "../assets/img/PLANTAS3.jpeg"
import P5 from "../assets/img/PLANTAS4.jpeg"
import P6 from "../assets/img/PLANTAS5.jpeg"
import P7 from "../assets/img/PLANTAS6.jpeg"
import P8 from "../assets/img/PLANTAS7.jpeg"
import P9 from "../assets/img/PLANTAS8.jpeg"
import P10 from "../assets/img/PLANTAS9.jpeg"
import P11 from "../assets/img/PLANTAS10.jpeg"
import P12 from "../assets/img/PLANTAS11.jpeg"


import C1 from "../assets/img/CENA0.jpeg"
import C2 from "../assets/img/CENA1.jpeg"
import C3 from "../assets/img/CENA2.jpeg"
import C4 from "../assets/img/CENA12.jpeg"
import C5 from "../assets/img/CENA13.jpeg"
import C6 from "../assets/img/CENA5.jpeg"
import C7 from "../assets/img/CENA6.jpeg"
import C8 from "../assets/img/CENA7.jpeg"
import C9 from "../assets/img/CENA8.jpeg"
import C10 from "../assets/img/CENA9.jpeg"
import C11 from "../assets/img/CENA10.jpeg"
import C12 from "../assets/img/CENA11.jpeg"




import D1 from "../assets/img/DECO0.jpeg"
import D2 from "../assets/img/DECO12.jpeg"
import D3 from "../assets/img/DECO2.jpeg"
import D4 from "../assets/img/DECO3.jpeg"
import D5 from "../assets/img/DECO4.jpeg"
import D6 from "../assets/img/DECO5.jpeg"
import D7 from "../assets/img/DECO6.jpeg"
import D8 from "../assets/img/DECO7.jpeg"
import D9 from "../assets/img/DECO8.jpeg"
import D10 from "../assets/img/DECO9.jpeg"
import D11 from "../assets/img/DECO10.jpeg"
import D12 from "../assets/img/DECO16.JPEG"






import J1 from "../assets/img/JARDIM0.jpeg"
import J2 from "../assets/img/JARDIM1.jpeg"
import J3 from "../assets/img/JARDIM2.jpeg"
import J4 from "../assets/img/JARDIM3.jpeg"
import J5 from "../assets/img/JARDIM4.jpeg"
import J6 from "../assets/img/JARDIM5.jpeg"
import J7 from "../assets/img/JARDIM6.jpeg"
import J8 from "../assets/img/JARDIM7.jpeg"
import J9 from "../assets/img/JARDIM8.jpeg"
import J10 from "../assets/img/JARDIM9.jpeg"
import J11 from "../assets/img/DECO15.jpeg"
import J12 from "../assets/img/JARDIM12.jpeg"













export const ProjectCard = () => {
  const [visible, setVisible] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [width] = useWindowSize() // Get the screen width

  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: P1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: P2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: P3,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: P4,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: P5,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: P6,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: P7, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: P8, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: P9, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: P10, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: P11, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: P12, // Pode reutilizar imagens
    },

  ]

  const imageUrls = projects.map(project => ({ src: project.imgUrl }))

  const handleImageClick = (index) => {
    if (width > 575) { // Check if the screen width is greater than 768px
      setVisible(true)
      setActiveIndex(index)
    }
  }

  return (
    <>
      {projects.map((project, index) => (
        <Col size={12} sm={6} md={4} key={index}>
          <div className="proj-imgbx">
            <button onClick={() => handleImageClick(index)}>
              <img className="project-image" src={project.imgUrl} alt="Project" />
            </button>
          </div>
        </Col>
      ))}
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={imageUrls}
        activeIndex={activeIndex}
        className="viewer-custom"

      />
    </>
  )
}


export const ProjectCard2 = () => {
  const [visible, setVisible] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [width] = useWindowSize() // Get the screen width

  const handleImageClick = (index) => {
    if (width > 575) { // Check if the screen width is greater than 768px
      setVisible(true)
      setActiveIndex(index)
    }
  }

  const projects = [
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C1,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C2,
    },

    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C3,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C4, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C5, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C6, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C7, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C8, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C9, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C10, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C11, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: C12, // Pode reutilizar imagens
    },
  ]

  const imageUrls = projects.map(project => ({ src: project.imgUrl }))

  return (
    <>
      {projects.map((project, index) => (
        <Col size={12} sm={6} md={4} key={index}>
          <div className="proj-imgbx">
            <button onClick={() => handleImageClick(index)}>
              <img className="project-image" src={project.imgUrl} alt="Project" />
            </button>
          </div>
        </Col>
      ))}
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={imageUrls}
        activeIndex={activeIndex}
        className="viewer-custom"

      />
    </>
  )
}
export const ProjectCard3 = () => {
  const [visible, setVisible] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [width] = useWindowSize() // Get the screen width

  const handleImageClick = (index) => {
    if (width > 575) { // Check if the screen width is greater than 768px
      setVisible(true)
      setActiveIndex(index)
    }
  }

  const projects = [
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D1,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D2,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D3,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D4, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D5, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D6, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D7, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D8, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D9, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D10, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D11, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: D12, // Pode reutilizar imagens
    },
  ]

  const imageUrls = projects.map(project => ({ src: project.imgUrl }))

  return (
    <>
      {projects.map((project, index) => (
        <Col size={12} sm={6} md={4} key={index}>
          <div className="proj-imgbx">
            <button onClick={() => handleImageClick(index)}>
              <img className="project-image" src={project.imgUrl} alt="Project" />
            </button>
          </div>
        </Col>
      ))}
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={imageUrls}
        activeIndex={activeIndex}
        className="viewer-custom"

      />
    </>
  )
}
export const ProjectCard4 = () => {
  const [visible, setVisible] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [width] = useWindowSize() // Get the screen width

  const handleImageClick = (index) => {
    if (width > 575) { // Check if the screen width is greater than 768px
      setVisible(true)
      setActiveIndex(index)
    }
  }

  const projects = [
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J1,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J2,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J3,
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J4, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J5, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J6, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J7, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J8, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J9, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J10, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J11, // Pode reutilizar imagens
    },
    {
      title: "New Project",
      description: "Design & Development",
      imgUrl: J12, // Pode reutilizar imagens
    },
  ]

  const imageUrls = projects.map(project => ({ src: project.imgUrl }))

  return (
    <>
      {projects.map((project, index) => (
        <Col size={12} sm={6} md={4} key={index}>
          <div className="proj-imgbx">
            <button onClick={() => handleImageClick(index)}>
              <img className="project-image" src={project.imgUrl} alt="Project" />
            </button>
          </div>
        </Col>
      ))}
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={imageUrls}
        activeIndex={activeIndex}
        className="viewer-custom"

      />
    </>
  )
}