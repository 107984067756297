import './App.css'
import { Banner } from './components/Banner'
import 'bootstrap/dist/css/bootstrap.min.css'
import { NavBar } from './components/NavBar.js'
// import { Skills } from './components/Skills.js'
import 'react-multi-carousel/lib/styles.css'
import { Projects } from './components/Projects.js'
import { Sobre } from './components/Sobre.js'
import { Test } from './components/Test.js'
function App() {
  return (
    <div className="App">

      <NavBar />

      <Banner />
      <Projects />
      <Test />
    </div >
  )
}

export default App


