import { useState, useEffect } from 'react'
import header from "../assets/img/header.jpg"
import { ArrowRightCircle } from "react-bootstrap-icons"
import { Container, Row, Col } from "react-bootstrap"

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [text, setText] = useState('')
  const [delta, setDelta] = useState(300 - Math.random() * 100)
  const [index, setIndex] = useState(1)
  const toRotate = ["Web Developer", "Web Designer", "UI/UX Designer"]
  const period = 10

  useEffect(() => {
    let ticker = setInterval(() => {
      tick()
    }, delta)

    return () => { clearInterval(ticker) }
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length
    let fullText = toRotate[i]
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1)

    setText(updatedText)

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2)
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true)
      setIndex(prevIndex => prevIndex - 1)
      setDelta(period)
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false)
      setLoopNum(loopNum + 1)
      setIndex(1)
      setDelta(500)
    } else {
      setIndex(prevIndex => prevIndex + 1)
    }
  }


  return (
    <section className="banner" id="inicio">
      <Container>
        <Row className="align-items-center">
          <Col xs={14} md={14} xl={11}>
            <span className="tagline">Sejam bem vindos a HB Garden</span>
            <h1>{`Paisagismo, Jardinagem e Decoração`}<span className="wrap">{ }</span></h1>
            {/* <p>Trabalhamos com todo tipo de design de interiores, incluindo aluguéis de plantas para casamento</p> */}
            {/* <button onClick={() => console.log('conectado')}> Conecte-se conosco! <ArrowRightCircle size={25} /></button> */}
          </Col>
        </Row>
      </Container>

    </section>
  )
}